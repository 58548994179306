import { styled } from '@invisible/ui/themes'
import { MutableRefObject, useCallback, useEffect, useRef } from 'react'

const Container = styled.div`
  width: 8px;
  border: 1px solid gray;
  background-color: gray;
  cursor: col-resize;
`

const ResizeHandle = () => {
  const dragHandleRef = useRef<HTMLDivElement | null>()
  const dragStartMousePosition = useRef({ x: 0, y: 0 })
  const leftWidth = useRef(0)

  const mouseMoveHandler = useCallback((e) => {
    const leftSide = dragHandleRef.current?.previousElementSibling as HTMLElement
    const rightSide = dragHandleRef.current?.nextElementSibling as HTMLElement

    const dx = e.clientX - dragStartMousePosition.current.x
    const newLeftWidth = ((leftWidth.current + dx) / window.innerWidth) * 100

    leftSide.style.width = `${newLeftWidth}%`
    rightSide.style.width = `${100 - newLeftWidth}%`

    leftSide.style.userSelect = 'none'
    leftSide.style.pointerEvents = 'none'

    rightSide.style.userSelect = 'none'
    rightSide.style.pointerEvents = 'none'
  }, [])

  const mouseUpHandler = useCallback(() => {
    const leftSide = dragHandleRef.current?.previousElementSibling as HTMLElement
    const rightSide = dragHandleRef.current?.nextElementSibling as HTMLElement

    document.body.style.removeProperty('cursor')

    leftSide.style.removeProperty('user-select')
    leftSide.style.removeProperty('pointer-events')

    rightSide.style.removeProperty('user-select')
    rightSide.style.removeProperty('pointer-events')

    window.getSelection()?.removeAllRanges()

    document.removeEventListener('mousemove', mouseMoveHandler)
    document.removeEventListener('mouseup', mouseUpHandler)
  }, [])

  const mouseDownHandler = useCallback(
    (e) => {
      const left = dragHandleRef.current?.previousElementSibling
      dragStartMousePosition.current = {
        x: e.clientX,
        y: e.clientY,
      }
      leftWidth.current = left?.getBoundingClientRect().width as number

      // Attach the listeners to `document`
      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    },
    [mouseMoveHandler, mouseUpHandler]
  )

  useEffect(() => {
    const element = dragHandleRef.current
    element?.addEventListener('mousedown', mouseDownHandler)

    return () => {
      element?.removeEventListener('mousedown', mouseDownHandler)
    }
  }, [mouseDownHandler])

  return <Container ref={dragHandleRef as MutableRefObject<HTMLDivElement>} />
}

export { ResizeHandle }
