import { Modal } from '@invisible/ui/modal'
import { useRouter } from 'next/router'
import { Dispatch, FC, SetStateAction } from 'react'
import { Box, Flex } from 'rebass'

interface IProps {
  setHelpModalOpen: Dispatch<SetStateAction<boolean>>
}

// eslint-disable-next-line @typescript-eslint/ban-types
const HelpModal: FC<IProps> = ({ setHelpModalOpen }) => {
  const router = useRouter()
  const isGenericMode = router.pathname === '/generic'

  return (
    <Modal absolute onClose={() => setHelpModalOpen(false)}>
      <Flex p={4} flexDirection='column'>
        <Box>
          Instructions: Load an image or PDF file, then click on any highlighted regions to select
          the text. Use the copy button then paste into a spreadsheet.
        </Box>
        <Box mt={4} fontSize={14}>
          Keyboard Shortcuts:
          {isGenericMode ? (
            <ul>
              <li>
                <kbd>WASD</kbd> or arrow keys for navigating the table cells.
              </li>
              <li>
                <kbd>q</kbd> will create a new row below the currently selected row.
              </li>
              <li>
                <kbd>c</kbd> will &quot;clear&quot; the contents of a cell.
              </li>
              <li>
                <kbd>x</kbd> will delete the currently selected row.
              </li>
              <li>
                <kbd>h</kbd> will open and close the help modal.
              </li>
              <li>
                <kbd>Tab</kbd> will move your cursor into the preview window at the top.
              </li>
              <li>
                <kbd>CTRL + Z</kbd> will undo your changes.
              </li>
              <li>
                <kbd>CTRL + Y</kbd> will redo your changes.
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <kbd>WASD</kbd> or arrow keys for navigating the table cells.
              </li>
              <li>
                <kbd>q</kbd> will create a duplicate of the current row type.
              </li>
              <li>
                <kbd>Q</kbd> will create a child of the current row.
              </li>
              <li>
                <kbd>c</kbd> will &quot;clear&quot; the contents of a cell.
              </li>
              <li>
                <kbd>z</kbd> will remove every space in a cell.
              </li>
              <li>
                <kbd>x</kbd> will multiply a price cell by 100.
              </li>
              <li>
                <kbd>h</kbd> will open and close the help modal.
              </li>
              <li>
                <kbd>e</kbd> will toggle between &quot;word&quot; and &quot;paragraph&quot; mode.
              </li>
              <li>
                <kbd>1</kbd> will change an item to a category.
              </li>
              <li>
                <kbd>2</kbd> will change a category to an item.
              </li>
              <li>
                <kbd>3</kbd> will change an item to an extra.
              </li>
              <li>
                <kbd>Tab</kbd> will move your cursor into the preview window at the top.
              </li>
              <li>
                <kbd>CTRL + Z</kbd> will undo your changes.
              </li>
              <li>
                <kbd>CTRL + Y</kbd> will redo your changes.
              </li>
            </ul>
          )}
        </Box>
      </Flex>
    </Modal>
  )
}

export { HelpModal }
