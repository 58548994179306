const languages = [
  {
    name: 'Afrikaans',
    value: 'af',
  },
  {
    name: 'Albanian',
    value: 'sq',
  },
  {
    name: 'Arabic',
    value: 'ar',
  },
  {
    name: 'Armenian',
    value: 'hy',
  },
  {
    name: 'Belorussian',
    value: 'be',
  },
  {
    name: 'Bengali',
    value: 'bn',
  },
  {
    name: 'Bulgarian',
    value: 'bg',
  },
  {
    name: 'Catalan',
    value: 'ca',
  },
  {
    name: 'Chinese',
    value: 'zh',
  },
  {
    name: 'Croatian',
    value: 'hr',
  },
  {
    name: 'Czech',
    value: 'cs',
  },
  {
    name: 'Danish	',
    value: 'da',
  },
  {
    name: 'Dutch',
    value: 'nl',
  },
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Estonian',
    value: 'et',
  },
  {
    name: 'Filipino',
    value: 'fil',
  },
  {
    name: 'Finnish',
    value: 'fi',
  },
  {
    name: 'French',
    value: 'fr',
  },
  {
    name: 'German',
    value: 'de',
  },
  {
    name: 'Greek',
    value: 'el',
  },
  {
    name: 'Gujarati',
    value: 'gu',
  },
  {
    name: 'Hebrew',
    value: 'iw',
  },
  {
    name: 'Hindi',
    value: 'hi',
  },
  {
    name: 'Hungarian',
    value: 'hu',
  },
  {
    name: 'Icelandic',
    value: 'is',
  },
  {
    name: 'Indonesian',
    value: 'id',
  },
  {
    name: 'Italian',
    value: 'it',
  },
  {
    name: 'Japanese',
    value: 'ja',
  },
  {
    name: 'Kannada',
    value: 'kn',
  },
  {
    name: 'Khmer',
    value: 'km',
  },
  {
    name: 'Korean',
    value: 'ko',
  },
  {
    name: 'Lao',
    value: 'lo',
  },
  {
    name: 'Latvian',
    value: 'lv',
  },
  {
    name: 'Lithuanian',
    value: 'lt',
  },
  {
    name: 'Macedonian',
    value: 'mk',
  },
  {
    name: 'Malay',
    value: 'ms',
  },
  {
    name: 'Malayalam',
    value: 'ml',
  },
  {
    name: 'Marathi',
    value: 'mr',
  },
  {
    name: 'Nepali',
    value: 'ne',
  },
  {
    name: 'Norwegian',
    value: 'no',
  },
  {
    name: 'Persian',
    value: 'fa',
  },
  {
    name: 'Polish	',
    value: 'pl',
  },
  {
    name: 'Portuguese',
    value: 'pt',
  },
  {
    name: 'Punjabi',
    value: 'pa',
  },
  {
    name: 'Romanian',
    value: 'ro',
  },
  {
    name: 'Russian',
    value: 'ru',
  },
  {
    name: 'Serbian',
    value: 'sr',
  },
  {
    name: 'Slovak',
    value: 'sk',
  },
  {
    name: 'Slovenian',
    value: 'sl',
  },
  {
    name: 'Spanish',
    value: 'es',
  },
  {
    name: 'Swedish',
    value: 'sv',
  },
  {
    name: 'Tamil',
    value: 'ta',
  },
  {
    name: 'Telugu',
    value: 'te',
  },
  {
    name: 'Thai',
    value: 'th',
  },
  {
    name: 'Turkish',
    value: 'tr',
  },
  {
    name: 'Ukrainian',
    value: 'tk',
  },
  {
    name: 'Vietnamese',
    value: 'vi',
  },
  {
    name: 'Yiddish',
    value: 'yi',
  },
]

export default languages
