import { Button } from '@invisible/ui/button'
import { Spinner } from '@invisible/ui/spinner'
import { styled } from '@invisible/ui/themes'
import axios from 'axios'
import { last } from 'lodash/fp'
import { FormEvent, useState } from 'react'
import { Box, Flex } from 'rebass'

import { IMenu } from '../models/Menu'
import { withFirebase } from './Firebase/FirebaseContext'
import { FormContainer } from './UploadForm'

export const LocationInput = styled.input`
  font-size: 14px;
  padding: 4px;
  margin: 0 1rem;
`

export const LocationLabel = styled.span`
  margin-right: 1rem;
  font-weight: bold;
  display: inline-block;
  padding-right: 1rem;
`
const UrlFormComponent = (props: {
  menuLoadHandler: (menu: IMenu) => void
  firebase: {
    docRef: any
    getAnalytics: () => any
  }
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const [status, setStatus] = useState('')
  const imageLoadHandler = async (event: FormEvent) => {
    event.preventDefault()
    if (!imageUrl) {
      return
    }
    if (last(imageUrl.split('.')) === 'pdf') {
      alert('Cant load PDF files')
      setImageUrl('')
      return
    }
    try {
      setIsLoading(true)
      setStatus('Loading Remote Image')
      const response = await axios.post('/api/save-image-url', {
        imageUrl,
      })
      props.firebase.getAnalytics().logEvent('loaded_image_url')
      setStatus('Done')
      setIsLoading(false)
      props.menuLoadHandler(response.data.menu)
    } catch (error) {
      setIsLoading(false)
      alert('error: ' + JSON.stringify(error))
    }
  }
  if (isLoading) {
    return (
      <Flex alignItems='center'>
        <Spinner></Spinner>
        {status || 'Working...'}
        <Button
          onClick={() => {
            setImageUrl(undefined)
            setIsLoading(false)
          }}>
          Cancel
        </Button>
      </Flex>
    )
  }
  return (
    <form onSubmit={imageLoadHandler}>
      <FormContainer p={3} justifyContent='flex-start' flexDirection='column'>
        <label>
          <LocationLabel>Image URL</LocationLabel>
          <LocationInput
            type='text'
            defaultValue={imageUrl}
            onChange={(event) => {
              setImageUrl(event.currentTarget.value)
            }}
          />
        </label>
        <Button>Load</Button>
        <Box fontSize={1} my={3}>
          (Full URL to a JPG or PNG file.)
        </Box>
      </FormContainer>
    </form>
  )
}

export const UrlForm = withFirebase(UrlFormComponent)
