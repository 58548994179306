import { Button } from '@invisible/ui/button'
import { DropdownMenu } from '@invisible/ui/dropdown-menu'
import {
  BaseOutlineIcon,
  HelpIcon,
  ParagraphIcon,
  TextModeIcon,
  WordModeIcon,
} from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { Modal } from '@invisible/ui/modal'
import { purple, styled } from '@invisible/ui/themes'
import { useRouter } from 'next/router'
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'

import { IMenu } from '../models/Menu'
import {
  loadMenu,
  rotateLeft,
  rotateRight,
  setAnnotationVisible,
  setIsEditing,
  setTableMode,
  setWordMode,
  zoomIn,
  zoomOut,
} from '../state/ocrSlice'
import { ocrStateSelector } from '../state/selectors'
import { HelpModal } from './HelpModal'
import { UploadForm } from './UploadForm'
import { UrlForm } from './UrlForm'

const Container = styled(Flex)`
  background: ${purple(10)};
  height: 50px;
  align-items: center;
  justify-content: space-between;
`
const DropdownContainer = styled(Flex)`
  flex-direction: column;
  background: ${purple(10)};
`

const SearchInput = styled(Input)`
  position: sticky;
  bottom: 10px;
  left: 50%;

  & > div {
    border: 1px solid black;
  }
`

// eslint-disable-next-line @typescript-eslint/ban-types
const Toolbar: FC<{
  onSearchChange: (value: string) => void
  searchText: string
}> = (props) => {
  const [loadModalOpen, setLoadModalOpen] = useState(true)
  const [helpModalOpen, setHelpModalOpen] = useState(false)

  const dispatch = useDispatch()
  const router = useRouter()

  const tableModeButtonRef = useRef<HTMLElement>(null)
  const wordModeButtonRef = useRef<HTMLElement>(null)

  const isGenericMode = useMemo(() => router?.pathname === '/generic', [router])

  const { zoomLevel, imageText, wordMode, tableMode, annotationsVisible, isEditing } =
    useSelector(ocrStateSelector)

  const handleKeyPress = (event: KeyboardEvent) => {
    if (isEditing) return
    switch (event.key) {
      case 'h':
        setHelpModalOpen(!helpModalOpen)
        break
      case 'l':
        setLoadModalOpen(!loadModalOpen)
        break
      case 'e':
        dispatch(setWordMode(!wordMode))
        break
      default:
        break
    }
  }
  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress)
    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  })
  return (
    <>
      <Container>
        <Flex>
          <Button variant='subtle' size='md' onClick={() => setHelpModalOpen(true)}>
            <Flex alignItems='center'>
              <HelpIcon width='16px' height='16px' />
              <Box color='white' pl={2}>
                Help
              </Box>
            </Flex>
          </Button>
          <div className='mx-2'>
            <Button iconLeft='LoadMenuIcon' size='md' onClick={() => setLoadModalOpen(true)}>
              Load File
            </Button>
          </div>

          <DropdownMenu
            button={
              <Button variant='primary' size='md' iconRight='CaretDownIcon'>
                {isGenericMode ? 'Generic Mode' : 'Doordash Mode'}
              </Button>
            }>
            <DropdownContainer width={160}>
              {isGenericMode ? (
                <Button
                  variant='subtle'
                  color='void'
                  size='md'
                  onClick={() => {
                    router.push('/')
                  }}>
                  Doordash Mode
                </Button>
              ) : (
                <Flex alignItems='center' justifyContent='center' py='15px'>
                  <Box color='white' fontSize='14px'>
                    Doordash Mode
                  </Box>
                </Flex>
              )}
              {!isGenericMode ? (
                <Button
                  variant='subtle'
                  color='void'
                  size='md'
                  onClick={() => {
                    router.push('/generic')
                  }}>
                  Generic Mode
                </Button>
              ) : (
                <Flex alignItems='center' justifyContent='center' py='15px'>
                  <Box color='white' fontSize='14px'>
                    Generic Mode
                  </Box>
                </Flex>
              )}
            </DropdownContainer>
          </DropdownMenu>
          {!isGenericMode ? (
            <DropdownMenu
              button={
                <Button
                  variant='primary'
                  size='md'
                  iconLeft={tableMode ? 'BaseOutlineIcon' : 'TextModeIcon'}
                  iconRight='CaretDownIcon'>
                  {tableMode ? 'Table' : 'Text'}
                </Button>
              }>
              <DropdownContainer ref={tableModeButtonRef} width={160}>
                {tableMode ? (
                  <Button
                    variant='subtle'
                    color='void'
                    size='md'
                    iconLeft='TextModeIcon'
                    onClick={() => {
                      dispatch(setTableMode(false))
                      tableModeButtonRef.current?.remove()
                    }}>
                    Text
                  </Button>
                ) : (
                  <Flex alignItems='center' py='15px' pl={3}>
                    <TextModeIcon width='16px' height='16px' />
                    <Box color='white' pl={2} fontSize='14px'>
                      Text
                    </Box>
                  </Flex>
                )}
                {!tableMode ? (
                  <Button
                    variant='subtle'
                    color='void'
                    size='md'
                    iconLeft='BaseOutlineIcon'
                    onClick={() => {
                      dispatch(setTableMode(true))
                      tableModeButtonRef.current?.remove()
                    }}>
                    Table
                  </Button>
                ) : (
                  <Flex alignItems='center' py='15px' pl={3}>
                    <BaseOutlineIcon width='16px' height='16px' />
                    <Box color='white' pl={2} fontSize='14px'>
                      Table
                    </Box>
                  </Flex>
                )}
              </DropdownContainer>
            </DropdownMenu>
          ) : (
            ''
          )}
        </Flex>

        <Flex alignItems='center' color='white'>
          <Button
            variant='subtle'
            color='void'
            size='md'
            iconLeft='RotateLeftIcon'
            onClick={() => dispatch(rotateLeft())}>
            {''}
          </Button>
          <Button
            variant='subtle'
            color='void'
            size='md'
            iconLeft='RotateRightIcon'
            onClick={() => dispatch(rotateRight())}>
            {''}
          </Button>
          <div className='ml-5'>
            <Button
              variant='subtle'
              color='void'
              size='md'
              iconLeft='ZoomOutIcon'
              onClick={() => dispatch(zoomOut())}>
              {''}
            </Button>
          </div>

          <div className='w-10'>{zoomLevel}%</div>
          <Button
            variant='subtle'
            color='void'
            size='md'
            iconLeft='ZoomInIcon'
            onClick={() => dispatch(zoomIn())}>
            {''}
          </Button>
        </Flex>
        <Flex onMouseLeave={() => isEditing && dispatch(setIsEditing(false))}>
          <DropdownMenu
            button={
              <Button variant='primary' size='md' iconLeft='SearchOutlineIcon'>
                Search
              </Button>
            }>
            <DropdownContainer ref={wordModeButtonRef} width={210}>
              <Flex alignItems='center' justifyContent='space-around' width='100%' py={1}>
                <SearchInput
                  prefix='SearchOutlineIcon'
                  value={props.searchText}
                  backgroundColor='white'
                  width='200px'
                  placeholder='Search...'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.onSearchChange(e.target.value)
                  }
                  onClick={() => dispatch(setIsEditing(true))}
                  onBlur={() => dispatch(setIsEditing(false))}
                />
              </Flex>
            </DropdownContainer>
          </DropdownMenu>
          <div className='ml-2'></div>
          <Button
            variant={annotationsVisible ? 'primary' : 'subtle'}
            color='void'
            size='md'
            iconLeft='ToggleAnnotationsIcon'
            onClick={() => dispatch(setAnnotationVisible(!annotationsVisible))}>
            Toggle Annotations
          </Button>
          <DropdownMenu
            button={
              <Button
                variant='primary'
                size='md'
                iconLeft={wordMode ? 'WordModeIcon' : 'ParagraphIcon'}
                iconRight='CaretDownIcon'>
                {!wordMode ? 'Paragraph Mode' : 'Word Mode'}
              </Button>
            }>
            <DropdownContainer ref={wordModeButtonRef} width={200}>
              {!wordMode ? (
                <Button
                  variant='subtle'
                  color='void'
                  size='md'
                  iconLeft='WordModeIcon'
                  onClick={() => {
                    dispatch(setWordMode(true))
                    wordModeButtonRef.current?.remove()
                  }}>
                  Word Mode
                </Button>
              ) : (
                <Flex alignItems='center' py='15px' pl={3}>
                  <WordModeIcon width='16px' height='16px' />
                  <Box color='white' pl={2} fontSize='14px'>
                    Word Mode
                  </Box>
                </Flex>
              )}

              {wordMode ? (
                <Button
                  variant='subtle'
                  color='void'
                  size='md'
                  onClick={() => {
                    dispatch(setWordMode(false))
                    wordModeButtonRef.current?.remove()
                  }}>
                  <Flex alignItems='center' width='100%' py={1}>
                    <ParagraphIcon width='16px' height='16px' />
                    <Box color='white' pl={2}>
                      Paragraph Mode
                    </Box>
                  </Flex>
                </Button>
              ) : (
                <Flex alignItems='center' py='15px' pl={3}>
                  <ParagraphIcon width='16px' height='16px' />
                  <Box color='white' pl={2} fontSize='14px'>
                    Paragraph Mode
                  </Box>
                </Flex>
              )}
            </DropdownContainer>
          </DropdownMenu>

          <div className='ml-2'>
            <Button
              variant='subtle'
              color='void'
              size='md'
              iconLeft='CopyOutlineIcon'
              onClick={() => navigator.clipboard.writeText(imageText)}>
              Copy All Text
            </Button>
          </div>
        </Flex>
      </Container>
      {loadModalOpen ? (
        <Modal onClose={() => setLoadModalOpen(false)}>
          <Box p={20}>
            <UploadForm
              menuLoadHandler={async (menu: IMenu, language: string) => {
                await dispatch(loadMenu({ menu, language }))
                setLoadModalOpen(false)
              }}
            />
            <UrlForm
              menuLoadHandler={async (menu: IMenu, language: string) => {
                await dispatch(loadMenu({ menu, language }))
                setLoadModalOpen(false)
              }}
            />
          </Box>
        </Modal>
      ) : null}
      {helpModalOpen ? <HelpModal setHelpModalOpen={setHelpModalOpen} /> : null}
    </>
  )
}
export { Toolbar }
