import { Spinner } from '@invisible/ui/spinner'
import { Text } from '@invisible/ui/text'
import { shadows, styled } from '@invisible/ui/themes'
import { useRouter } from 'next/router'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'

import Firebase from '../lib/firebase'
import { loadMenu } from '../state/ocrSlice'
import { ocrStateSelector } from '../state/selectors'
import { ImageDisplay } from './ImageDisplay'
import { ResizeHandle } from './ResizeHandle'
import { Titlebar } from './Titlebar'
import { Toolbar } from './Toolbar'

const Content = styled(Flex)`
  height: calc(100vh - 114px);
`

const Left = styled(Box)`
  background: white;
  box-shadow: ${shadows('medium')};
  width: 35%;
  height: 100%;
  border-right: 1px solid gray;
`

const Right = styled(Box)`
  background: #f1f2f7;
  min-height: 100%;
  background-image: url('./DotBackground.png');
  width: 65%;
  position: relative;
  overflow: auto;
`

const WelcomeMessage = styled.div`
  position: absolute;
  left: 20%;
  top: 30%;
  font-size: 20px;
  .welcome {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`
// eslint-disable-next-line @typescript-eslint/ban-types
const Layout: FC = ({ children }) => {
  const {
    rotationAngle,
    loading,
    words,
    blocks,
    imageUrls,
    imageSizes,
    wordMode,
    annotationsVisible,
    activeMenu,
  } = useSelector(ocrStateSelector)
  const router = useRouter()
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    async function getExistingMenu() {
      if (activeMenu) return

      // split at first `?`
      const searchParams = new URLSearchParams(
        router.asPath.split(/\?/)[1]
      ) as unknown as string[][]

      const query: Record<string, string> = {}
      for (const [key, value] of searchParams) {
        query[key] = value
      }

      if (query && query.hash) {
        const docRef = Firebase.docRef(query.hash)

        const doc = await docRef.get()

        let menu

        if (doc && doc.data()) {
          menu = doc.data()

          Firebase.getAnalytics().logEvent('get_menu')
        }

        dispatch(loadMenu({ menu: menu as any, language: menu?.language ?? '' }))
      }
    }

    getExistingMenu()
  }, [])

  const matchingWords = useMemo(() => {
    if (searchText === '') return {}

    const output: Record<string, any> = {}
    const keys = Object.keys(words)
    keys.forEach((key) => {
      const annotations = words[key]
      const filteredAnnotations = annotations.filter((e) =>
        e.text.toLowerCase().includes(searchText)
      )

      output[key] = filteredAnnotations
    })

    return output
  }, [searchText, words])

  return (
    <>
      <Titlebar />
      <Toolbar onSearchChange={(value) => setSearchText(value)} searchText={searchText} />

      <Content>
        <Left>{children}</Left>

        <ResizeHandle />

        <Right>
          {loading ? (
            <>
              <Spinner />
              <Text textAlign='center'>Analyzing Image... </Text>
            </>
          ) : Object.keys(words).length || Object.keys(blocks).length ? (
            <Box p={rotationAngle !== 0 ? '50%' : 0}>
              {Object.keys(imageSizes).map((page) => (
                <ImageDisplay
                  key={page}
                  polygons={!wordMode ? blocks[page] : words[page]}
                  imageUrl={imageUrls[page]}
                  size={imageSizes[page]}
                  hideNotes={annotationsVisible}
                  searchResults={matchingWords[page] ?? []}
                />
              ))}
            </Box>
          ) : (
            <WelcomeMessage>
              <div className='welcome'>Welcome!</div>
              <Box>Click the Load File button to load a file.</Box>
              <Box mt='20px'>
                Remember that &quot;h&quot; will open the Help screen, which lists keyboard
                shortcuts.
              </Box>
            </WelcomeMessage>
          )}
        </Right>
      </Content>
    </>
  )
}

export { Layout }
