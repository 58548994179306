import { PropsWithChildren, ReactElement, useState } from 'react'

interface IDropdownProps {
  button: ReactElement
}

const DropdownMenuComponent = (props: PropsWithChildren<IDropdownProps>) => {
  const [isOpen, setOpen] = useState(false)

  const { button, children } = props

  const handleMenuOpen = () => {
    setOpen(true)
  }

  const handleMenuClose = () => setOpen(false)

  return (
    <div
      className='relative inline-block'
      onClick={handleMenuOpen}
      onMouseEnter={handleMenuOpen}
      onMouseLeave={handleMenuClose}
      onFocus={handleMenuOpen}
      data-cy='dropdownMenu'>
      {button}
      {isOpen ? (
        <div
          className='border-weak-3 absolute right-auto z-[1001] overflow-hidden rounded-md border border-solid shadow-md'
          data-cy='dropdownMenuOpen'>
          {children}
        </div>
      ) : null}
    </div>
  )
}

const DropdownMenu = DropdownMenuComponent

export { DropdownMenu }
