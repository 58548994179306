import { Button } from '@invisible/ui/button'
import { Spinner } from '@invisible/ui/spinner'
import { Text } from '@invisible/ui/text'
import { styled } from '@invisible/ui/themes'
import axios from 'axios'
import { last } from 'lodash/fp'
import Router from 'next/router'
import { FC, FormEvent, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Flex } from 'rebass'

import { hash } from '../common/hash'
import languages from '../common/ocr-languages'
import { PDF_CONVERSION_SERVICE } from '../config/env'
import { Extensions } from '../models/Extensions'
import { IMenu } from '../models/Menu'
import { withFirebase } from './Firebase/FirebaseContext'

const bucketName = 'dd-menus-01'

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#604CA5'
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`

const fileReader = (file: File): Promise<string | ArrayBuffer> => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    reader.onload = () => {
      resolve(reader.result as string | ArrayBuffer)
    }
    reader.readAsArrayBuffer(file)
  })
}

export const FormContainer = styled(Flex)`
  background-color: #f1f1f1;
  border-radius: 8px;
  margin: 1rem 0;
  align-items: baseline;
`
const UploadLabel = styled.span`
  padding: 1rem 0;
  display: inline-block;
  font-weight: bold;
`

interface IUploadFormComponent {
  menuLoadHandler: (menu: Partial<IMenu> | undefined, language: string) => void
  firebase: {
    docRef: any
    getAnalytics: () => any
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const UploadFormComponent: FC<IUploadFormComponent> = (props) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      accept: 'image/jpeg, image/png, application/pdf',
      maxFiles: 1,
    })

  const [file, setFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [language, setLanguage] = useState('')

  useEffect(() => {
    setFile(acceptedFiles[0])
  }, [acceptedFiles])

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (file) {
      setIsLoading(true)
      setStatus('Calculating File Hash')

      const fileResult = await fileReader(file)
      const fileHash = hash(fileResult as string)

      const ext: string | undefined = Extensions[file.type]
      setStatus('Getting file storage location')
      const signedUrlResponse = await axios.post(
        '/api/sign-url?file=' + fileHash + ext + '&type=' + file.type
      )

      setStatus('Getting Menu Data')
      /** Adding file hash query params */
      Router.push({
        query: {
          hash: fileHash,
          ...(Router.query.columns ? { columns: Router.query.columns } : {}),
        },
      })

      const docRef = props.firebase.docRef(fileHash)

      const doc = await docRef.get()
      let menu

      if (doc && doc.data()) {
        menu = doc.data()

        props.firebase.getAnalytics().logEvent('get_existing_menu')
        setStatus('Menu already exists')
      } else {
        if (!ext) {
          throw 'Bad content type: ' + file.type
        }
        setStatus('Uploading file to storage')

        await axios.put(signedUrlResponse.data.url, file, {
          headers: {
            'Content-Type': file.type,
          },
        })

        props.firebase.getAnalytics().logEvent('file_uploaded', {
          type: file.type,
          name: file.name,
          size: file.size,
        })
        setStatus('Upload complete, saving to database')
        const remotePath = fileHash + ext
        const gsPath = `gs://${bucketName}/${remotePath}`
        menu = {
          hash: fileHash,
          fileName: remotePath,
          type: file.type,
          storagePath: gsPath,
          publicUrl: `https://storage.googleapis.com/${bucketName}/${remotePath}`,
          language,
        }
        await docRef.set(menu)
        setStatus('Saved to database')
      }
      if (!PDF_CONVERSION_SERVICE) {
        throw 'Missing configuration URL for PDF conversion service'
      }
      if (
        (menu?.type === 'application/pdf' ||
          (menu?.fileName && last(menu.fileName.split('.')) === 'pdf')) &&
        !menu.convertedImages
      ) {
        setStatus('Converting PDF file to Images')
        props.firebase.getAnalytics().logEvent('start_pdf_conversion')
        const converted = await axios.post(PDF_CONVERSION_SERVICE, { menu }, {})
        props.firebase.getAnalytics().logEvent('finish_pdf_conversion')
        if (converted.data && converted.data.menu) {
          props.menuLoadHandler(converted.data.menu, language)
        }
      } else {
        setIsLoading(false)
        props.menuLoadHandler(menu, language)
      }
    }
  }

  if (isLoading) {
    return (
      <>
        <Flex alignItems='center'>
          <Spinner />
          <Box ml={3}>{status || 'Working...'}</Box>
        </Flex>
        <Button
          onClick={() => {
            setFile(undefined)
            setIsLoading(false)
          }}>
          Cancel
        </Button>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer p={3} mt={2} justifyContent='flex-start' flexDirection='column'>
        <Flex justifyContent='space-between' alignItems='center' width='100%'>
          <label>
            <UploadLabel>Upload PDF or Image File</UploadLabel>
          </label>
          <div>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              name='languages'
              id='languages'>
              <option value=''>Autodetect Language</option>
              {languages.map((language) => (
                <option key={language.value} value={language.value}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
        </Flex>

        <section>
          <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            <p>Drag the file you want to load here, or click to select.</p>
          </Container>
          {acceptedFiles.length !== 0 ? (
            <div>
              <Text py={3}>File: {acceptedFiles[0].name}</Text>
            </div>
          ) : null}
        </section>

        <div className='mt-2'>
          <Button variant='primary' size='md'>
            Upload
          </Button>
        </div>
      </FormContainer>
    </form>
  )
}

export const UploadForm = withFirebase(UploadFormComponent)
