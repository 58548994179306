import { createSelector } from '@reduxjs/toolkit'

import { RootState } from './store'

const menuStateSelector = createSelector(
  (state: RootState) => state.menuState,
  (state) => state.present
)

const ocrStateSelector = createSelector(
  (state: RootState) => state.ocrState,
  (state) => state
)

const genericStateSelector = createSelector(
  (state: RootState) => state.genericState,
  (state) => state.present
)

export { genericStateSelector, menuStateSelector, ocrStateSelector }
