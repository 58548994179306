import { styled } from '@invisible/ui/themes'
import { chunk } from 'lodash'
import {
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IPolygon } from '../models/Menu'
import { selectWord } from '../state/ocrSlice'
import { ocrStateSelector } from '../state/selectors'
import { withFirebase } from './Firebase/FirebaseContext'

const ImagesContainer = styled.div<{
  rotationAngle: number
  zoomLevel: number
}>`
  position: relative;
  width: 100%;
  min-height: calc(100% - 75px);
  overflow: visible;
  .container {
    width: 100%;
    min-height: 100%;
    position: relative;
    transform-origin: 0 0;
    transform: ${({ zoomLevel }) => `scale(${zoomLevel / 100}, ${zoomLevel / 100})`};
  }
  svg {
    transform: ${({ rotationAngle }) => `rotate(${rotationAngle}deg)`};
  }
`

const FullSizeDiagram = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  polygon:hover {
    filter: invert(0.5) sepia(1) hue-rotate(200deg) saturate(4) brightness(1);
  }
`
const ImageDisplayComponent = (
  props: PropsWithChildren<{
    polygons: Array<{ text: string; coords: string }>
    imageUrl: string
    size: {
      width: number
      height: number
    }
    hideNotes: boolean
    searchResults: Array<{ text: string; coords: string }>
  }>
) => {
  const [mouseCoordinates, setMouseCoordinates] = useState([0, 0, 0, 0, 0, 0, 0, 0])
  const [isMouseDragging, setIsMouseDragging] = useState(false)

  const canvasRef = useRef<SVGSVGElement | null>(null)
  const { zoomLevel, rotationAngle } = useSelector(ocrStateSelector)

  const dispatch = useDispatch()

  const renderedPolys = props.polygons
    ? props.polygons.map((x: IPolygon) => (
        <polygon
          key={x.coords}
          onClick={() => {
            if (x?.text !== '') {
              dispatch(selectWord(x))
            }
          }}
          points={x.coords}
          fill={props.searchResults.some((e) => e.coords === x.coords) ? 'red' : 'green'}
          fillOpacity={props.searchResults.some((e) => e.coords === x.coords) ? '0.5' : '0.2'}
          stroke={
            props.searchResults.some((e) => e.coords === x.coords) ? 'black' : 'red'
          }></polygon>
      ))
    : ''

  const renderMousePoly = () => (
    <polygon
      key={222}
      points={mouseCoordinates.join(',')}
      fill='red'
      fillOpacity='0.2'
      stroke='blue'
      strokeDasharray='5,5'></polygon>
  )

  const getXYAxis = useCallback(
    (evt: ReactMouseEvent<SVGSVGElement, MouseEvent>, dim: DOMRect) => {
      let x = 0
      let y = 0

      //Restrict rotationAngle to +-270 degrees
      const regularizeAngle: (input: number) => number = (input: number) => {
        if (input > 0) {
          if (input < 360) return input

          input -= 360
          return regularizeAngle(input)
        } else {
          if (input > -360) return input

          input += 360
          return regularizeAngle(input)
        }
      }

      const angle = regularizeAngle(rotationAngle)

      if (angle === 0) {
        x = (evt.clientX - dim.left) / (zoomLevel / 100)
        y = (evt.clientY - dim.top) / (zoomLevel / 100)
      } else if (angle === 90 || angle === -270) {
        x = (evt.clientY - dim.top) / (zoomLevel / 100)
        y = (dim.right - evt.clientX) / (zoomLevel / 100)
      } else if (angle === 180 || angle === -180) {
        x = (dim.width - (evt.clientX - dim.left)) / (zoomLevel / 100)
        y = (dim.height - (evt.clientY - dim.top)) / (zoomLevel / 100)
      } else if (angle === 270 || angle === -90) {
        x = (dim.height - (evt.clientY - dim.top)) / (zoomLevel / 100)
        y = (dim.width - (dim.right - evt.clientX)) / (zoomLevel / 100)
      }

      return { x, y }
    },
    [zoomLevel, rotationAngle]
  )

  return (
    <div
      style={{
        overflow: 'visible',
        height: `${props.size.height * (zoomLevel / 100)}px`,
      }}>
      <ImagesContainer rotationAngle={rotationAngle} zoomLevel={zoomLevel}>
        <div className='container'>
          <FullSizeDiagram
            ref={canvasRef}
            width={props.size.width}
            height={props.size.height}
            key={props.imageUrl}
            onMouseDown={(evt) => {
              const dim = canvasRef.current?.getBoundingClientRect()

              if (!dim) return

              const { x, y } = getXYAxis(evt, dim)
              !isMouseDragging && setIsMouseDragging(true)
              const newCoordinates = [...mouseCoordinates]
              newCoordinates[0] = x
              newCoordinates[1] = y
              newCoordinates[2] = x
              newCoordinates[3] = y
              newCoordinates[4] = x
              newCoordinates[5] = y
              newCoordinates[6] = x
              newCoordinates[7] = y
              setMouseCoordinates(newCoordinates)
            }}
            onMouseUp={() => {
              isMouseDragging && setIsMouseDragging(false)
              const selectedWords: string[] = []
              // Go through every word
              props.polygons.map((word) => {
                //check word is inside selection
                const wordCoords = word.coords.replace(/\s/g, ',').split(',')
                const chunkedWordCoords = chunk(wordCoords, 2)
                let isSelected = false // Do not repeat same word
                const [x1, y1, x2, y2, x3, y3, x4, y4] = mouseCoordinates
                chunkedWordCoords.map((chunk) => {
                  const [x, y] = chunk
                  if (
                    (parseInt(y, 10) > y1 && // top left to bottom right
                      parseInt(y, 10) < y4 &&
                      parseInt(x, 10) > x1 &&
                      parseInt(x, 10) < x2 &&
                      !isSelected) ||
                    (parseInt(y, 10) < y1 && // bottom right to top left
                      parseInt(y, 10) > y4 &&
                      parseInt(x, 10) > x2 &&
                      parseInt(x, 10) < x1 &&
                      !isSelected) ||
                    (parseInt(x, 10) > x4 && // bottom left to top right
                      parseInt(x, 10) < x3 &&
                      parseInt(y, 10) > y4 &&
                      parseInt(y, 10) < y1 &&
                      !isSelected) ||
                    (parseInt(x, 10) > x2 && // top right to bottom left
                      parseInt(x, 10) < x1 &&
                      parseInt(y, 10) > y2 &&
                      parseInt(y, 10) < y4 &&
                      !isSelected)
                  ) {
                    selectedWords.push(word.text)
                    isSelected = true
                  }
                })
              })

              dispatch(selectWord({ text: selectedWords.join(' '), coords: '' }))
              setMouseCoordinates([0, 0, 0, 0, 0, 0, 0, 0])
            }}
            onMouseMove={(evt) => {
              if (isMouseDragging) {
                const dim = canvasRef.current?.getBoundingClientRect()

                if (!dim) return

                const { x, y } = getXYAxis(evt, dim)

                const newCoordinates = [...mouseCoordinates]
                newCoordinates[2] = x
                newCoordinates[4] = x
                newCoordinates[5] = y
                newCoordinates[7] = y
                setMouseCoordinates(newCoordinates)
              }
            }}>
            <image
              key={'img-' + props.imageUrl}
              href={props.imageUrl}
              x='0'
              y='0'
              width={`${props.size.width}px`}
              height={`${props.size.height}px`}
            />

            {props.polygons && !props.hideNotes ? renderedPolys : ''}
            {renderMousePoly()}
          </FullSizeDiagram>
        </div>
      </ImagesContainer>
    </div>
  )
}

export const ImageDisplay = withFirebase(ImageDisplayComponent)
