import { logout, useSession } from '@invisible/authentication/client'
import { Button } from '@invisible/ui/button'
import { AimIcon, InvisibleIcon } from '@invisible/ui/icons'
import { fontSizes, gray, styled } from '@invisible/ui/themes'
import { FC } from 'react'
import { Flex } from 'rebass'

const Container = styled.div`
  height: 64px;
  background: #2b2735;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  box-sizing: border-box;
`

const Logo = styled(Flex)`
  align-items: center;
  color: ${gray(3)};
  font-size: ${fontSizes(0)};
  letter-spacing: 4px;
`

const FirstHalf = styled.span`
  padding-left: 16px;
`

const SecondHalf = styled.span`
  padding-left: 4px;
`

const ProfilePicture = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const ProfileName = styled.span`
  color: white;
  font-size: 14px;
  line-height: 22px;
  padding-left: 10px;
`

const Profile = styled(Flex)`
  align-items: center;
  padding-right: 10px;

  button {
    border: none;
    background: none;
    cursor: pointer;
    padding-left: 16px;
    outline: none;
  }
`

// eslint-disable-next-line @typescript-eslint/ban-types
export const Titlebar: FC = () => {
  const { data: session } = useSession()

  return (
    <Container>
      <Flex alignItems='center'>
        <InvisibleIcon width='16px' height='16px' />
        <Logo>
          <FirstHalf>INVISI</FirstHalf>
          <AimIcon width='12px' height='12px' />
          <SecondHalf>NATOR</SecondHalf>
        </Logo>
      </Flex>

      <Profile>
        <ProfilePicture alt='Profile' src={session?.user?.image ?? ''} />
        <ProfileName>Hello {session?.user?.name?.split(' ')[0]}</ProfileName>
        <Button
          variant='primary'
          size='lg'
          icon='LogoutIcon'
          shape='square'
          onClick={() => logout()}></Button>
      </Profile>
    </Container>
  )
}
